import { CommonMargin } from 'components/_layout/CommonMargin';
import { Section } from 'components/_layout/Section/Section';
import { SocialMediaIcons } from 'components/_layout/SocialMediaIcons';
import { NotFoundInfo } from 'components/_universal/NotFoundInfo';
import React from 'react';
import { mediaQueries } from 'shared/breakpoints';
import styled from 'styled-components';
import { H1 } from 'styles/Typography.styled';

export const BigNumberHeader = styled(H1)`
    font-size: 230px;
    line-height: 180px;

    ${mediaQueries.xs} {
        font-size: 54vw;
    }
`;

export const SocialMediaIconsLeft = styled(SocialMediaIcons)`
    justify-content: flex-start;
`;

export default () => {
    return (
        <CommonMargin>
            <Section isFirst>
                <NotFoundInfo />
            </Section>
        </CommonMargin>
    );
};
